<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="IMAP"
      :properties="properties"
      step-label="imap"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedImapActions"
        :default-value="properties.action"
        step-label="imap"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <script-engines-select
      :key="`${step.id}-scriptEngine`"
      :default-value="properties.scriptEngine"
      step-label="imap"
      :readonly="!canEdit"
      required
      @change="handleChange('scriptEngine', $event)"
    />

    <v-col v-if="properties.action && properties.action !== 'ATTACHMENTS'" cols="12">
      <v-text-field
        :key="`${step.id}-folder`"
        v-model="properties.folder"
        outlined
        dense
        data-cy="imap-folder"
        :label="$lang.labels.folder"
        :readonly="!canEdit"
        :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
      ></v-text-field>
    </v-col>

    <v-col v-if="properties.action === 'SEARCH'" cols="12">
      <v-select
        :key="`${step.id}-readType`"
        v-model="properties.fields.readType"
        :items="READ_TYPES"
        :label="$lang.labels.readType"
        outlined
        dense
        required
        data-cy="imap-readType"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-sender`"
        v-model="properties.fields.sender"
        outlined
        dense
        :label="$lang.labels.sender"
        :readonly="!canEdit"
        data-cy="imap-sender"
        :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
      />
      <v-text-field
        :key="`${step.id}-recipient`"
        v-model="properties.fields.recipient"
        outlined
        dense
        :label="$lang.labels.recipient"
        :readonly="!canEdit"
        data-cy="imap-recipient"
        :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
      />
      <v-text-field
        :key="`${step.id}-subject`"
        v-model="properties.fields.subject"
        outlined
        dense
        :label="$lang.labels.subject"
        :readonly="!canEdit"
        data-cy="imap-subject"
        :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
      />
      <v-text-field
        :key="`${step.id}-body`"
        v-model="properties.fields.body"
        outlined
        dense
        :label="$lang.labels.body"
        data-cy="imap-body"
        :readonly="!canEdit"
        :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
      />
      <v-text-field
        :key="`${step.id}-maxResults`"
        v-model="properties.fields.maxResults"
        outlined
        dense
        :label="$lang.labels.maxResults"
        :readonly="!canEdit"
        data-cy="imap-maxResults"
        :rules="[(v) => v === '' || !!Number(v) || $lang.errors.wrongSearchParam]"
      />
    </v-col>

    <v-col v-if="properties.action && properties.action !== 'SEARCH'" cols="12">
      <v-text-field
        :key="`${step.id}-emailId`"
        v-model="properties.fields.emailId"
        outlined
        dense
        :label="$lang.labels.emailId"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        data-cy="imap-emailId"
        class="required-asterisk"
      />
    </v-col>

    <v-col v-if="['SEARCH', 'ATTACHMENTS'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        data-cy="imap-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect, ActionSelect, ScriptEnginesSelect } from './components'
import StepMixin from './StepMixin'

import { IMAP_ACTIONS, READ_TYPES } from './constants'

export default {
  name: 'IMAPStep',
  components: {
    CredentialsSelect,
    ActionSelect,
    ScriptEnginesSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      READ_TYPES
    }
  },
  computed: {
    formattedImapActions() {
      return IMAP_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  methods: {
    checkRegexFieldRecipient(val) {
      return this.$options.filters.checkComplexRegex(val)
    }
  }
}
</script>
